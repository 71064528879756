<template>
 <v-theme-provider dark>
  <base-section
    id="automaticdishwashing"
    class="accent"
    space="36"
  >
    <v-spacer />

    <div>
      <v-tabs
        class="hidden-sm-and-down"
        optional
        background-color="transparent"
      >
        <v-tab
          v-for="(name, i) in items"
          :key="i"
          :to="{ name }"
          :exact="name === 'Home'"
          :ripple="false"
          class="font-weight-bold"
          min-width="96"
          text
        >
          {{ name }}
        </v-tab>
      </v-tabs>
    </div>

    <v-container>
      <base-section-heading title="자동식기 세척기용 세제" />

      <v-container
      class="text-center"
      >
          자동식기세척기용 세제는 우수한 세척력과 동시에 살균력을 지닌 세제로써 한 번의 공정으로 탁월한 세척 효과를 발휘하여,
          음식물 찌꺼기에 의한 악취도 제거해 줍니다.<br>
          <br>
          저기포성으로 작업장의 청결을 유지 할 수 있을 뿐만 아니라, 금속염 봉쇄력이 탁월하여 기계내부에 스케일이 끼지 않습니다.<br>
          <br>
          린스제와 함께 사용함으로써 최종 수세 후 물방울 자국의 문제 해결 뿐만 아니라 식기를 위생적인 건조상태로 보관 하실 수 있습니다.<br>

      </v-container>
      <v-container>
      </v-container>

      <v-container>
      </v-container>
      <base-section-heading title="제품군" />
        <v-col
            cols="12"
            sm="6"
            offset-sm="3"
          >
            <v-card
            class="accent"
            >
              <v-subheader
              :inset="inset"
              >
                자동식기세척기용 분말세제
              </v-subheader>
            </v-card>
          </v-col>
        <v-col
            cols="12"
            sm="6"
            offset-sm="3"
          >
            <v-card
            class="accent"
            >
              <v-subheader
              :inset="inset"
              >
                자동식기세척기용 액상세제
              </v-subheader>
            </v-card>
        </v-col>
        <v-col
            cols="12"
            sm="6"
            offset-sm="3"
          >
            <v-card
            class="accent"
            >
              <v-subheader
              :inset="inset"
              >
                자동식기세척기용 린스제
              </v-subheader>
            </v-card>
        </v-col>
        <v-col
            cols="12"
            sm="6"
            offset-sm="3"
          >
            <v-card
            class="accent"
            >
              <v-subheader
              :inset="inset"
              >
                자동식기세척기용 스케일 제거제
              </v-subheader>
            </v-card>
          </v-col>
    </v-container>
  </base-section>
 </v-theme-provider>
</template>

<script>
  export default {
    name: 'Sectionautomaticdishwashing',
    data: () => ({
      drawer: null,
      items: [
        '자동식기 세척용',
        '주방용 세척제',
        '위생용 세척제',
        '세탁 및 카페트용',
      ],
      inset: false,
    }),
    provide: {
      theme: { isDark: true },
    },

    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },

    },

  }
</script>
<style scoped>
.v-subheader {
    font-size: 18px;
    color: coral !important;
}

</style>
